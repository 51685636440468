import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { Button } from '@wbk/ui';
import { useAuth } from '@wbk/auth';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import { SOCIAL_MEDIA } from '@/src/constants/social';
import { HEADER_NAV } from '../nav';
import MenuItem from './MenuItem';

type Props = {
  settings: LayoutProps['header'];
};

const MobileHeader = ({ settings }: Props) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const { t } = useTranslation();
  const { locale, pathname, asPath } = useRouter();
  const { user, loading } = useAuth({ autoFetch: isOpen });

  if (settings?.type === 'booking') return null;

  return (
    <>
      {isOpen && <style>{'body{overflow:hidden}'}</style>}
      <motion.nav
        className='absolute top-0 z-50 h-full w-full overflow-hidden text-xl font-semibold lg:hidden'
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { width: '100%' },
          closed: { width: 0, transition: { delay: 0.5 } },
        }}
      >
        <motion.div
          className='fixed top-0 z-20 h-full w-0 bg-body ltr:left-0 rtl:right-0'
          variants={{
            open: {
              width: '100vw',
            },
            closed: {
              width: 0,
              transition: {
                delay: 0.5,
                type: 'spring',
                stiffness: 400,
                damping: 40,
              },
            },
          }}
        />
        <motion.ul
          className='mini-scrollbar container absolute top-0 z-20 h-full max-h-screen w-full space-y-4 overflow-x-hidden py-20 ltr:left-0 rtl:right-0'
          variants={{
            open: {
              transition: { staggerChildren: 0.07, delayChildren: 0.2 },
            },
            closed: {
              transition: { staggerChildren: 0.05, staggerDirection: -1 },
            },
          }}
        >
          <MenuItem>
            {loading ? (
              <div className='space-y-1'>
                <div className='flex items-center gap-4'>
                  <div className='flex h-12 grow animate-pulse items-center rounded-t-lg bg-body-lighter'>
                    <div className='mx-2 h-10 w-10 animate-pulse rounded-full bg-body-light'></div>
                    <div className='h-8 w-3/4 animate-pulse rounded-lg bg-body-light'></div>
                  </div>
                </div>
                <div className='h-11 grow animate-pulse bg-body-lighter'></div>
                <div className='h-11 grow animate-pulse rounded-b-lg bg-body-lighter'></div>
              </div>
            ) : !user ? (
              <div className='mx-auto max-w-sm py-4'>
                <Button
                  as={Link}
                  href={`/${locale}/login?redirect=${asPath}`}
                  onClick={() => toggleOpen()}
                  data-testid='login-profile'
                  data-location='header'
                  className='w-full !px-1.5 !py-1.5 md:!px-2.5'
                >
                  <p className='shrink-0'>{t('common:auth.login_signup')}</p>
                </Button>
              </div>
            ) : (
              <Link
                href={`/${locale}/profile`}
                className='flex w-full items-center justify-between rounded-lg bg-white/5 p-2 hover:opacity-80'
                data-menuitem='profile'
                data-location='user-menu'
                onClick={() => toggleOpen()}
              >
                <div className='flex w-full items-center gap-4'>
                  <div className='relative flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-primary text-lg font-semibold uppercase text-white sm:mb-0'>
                    {`${user?.first_name?.[0]}${user?.last_name?.[0]}`}
                  </div>
                  <div className='text-lg font-semibold'>
                    <p className='line-clamp-1'>{user?.name}</p>
                    <p className='line-clamp-1 text-xs font-normal text-gray-500'>{user?.email}</p>
                  </div>
                </div>

                <Image
                  className='shrink-0 rtl:rotate-180'
                  src='/icons/common/arrow.svg'
                  width={25}
                  height={25}
                  alt=''
                />
              </Link>
            )}
          </MenuItem>
          {HEADER_NAV.map((link) => {
            const path = link[`href_${locale}` as 'href_en'] || link.href_en;
            return (
              <MenuItem key={path}>
                <Link
                  href={path}
                  className={`flex items-center gap-2 p-1 transition hover:text-primary focus:text-primary ${pathname === path ? 'text-primary' : ''}`}
                  data-title={link.title}
                  data-location='header'
                  onClick={() => toggleOpen()}
                >
                  {t(`common:nav.${link.title}`)}
                </Link>
              </MenuItem>
            );
          })}

          <MenuItem>
            <hr className='border-gray-700' />
          </MenuItem>

          <MenuItem>
            <ul className='my-8 flex items-center justify-center gap-4'>
              {SOCIAL_MEDIA.map((link) => (
                <li
                  key={link.en}
                  className='inline-flex rounded-lg border border-white transition-colors hover:bg-primary/10'
                >
                  <a
                    href={link[locale as 'en'] || link.en}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='social_media_follow p-2'
                    data-location='footer'
                    data-category={link.name}
                  >
                    <Image width={17} height={17} src={link.icon} alt={link.name} className='w-6' />
                  </a>
                </li>
              ))}
            </ul>
          </MenuItem>
        </motion.ul>
      </motion.nav>

      <ul className='container relative z-50 grid list-none grid-cols-4 items-center bg-body py-3 lg:hidden'>
        <li className='flex h-10 items-center justify-start'>
          <button
            className='relative flex h-12 w-12 items-center justify-center'
            onClick={() => toggleOpen()}
          >
            <AnimatePresence mode='wait'>
              {isOpen ? (
                <motion.div
                  key='open'
                  className='origin-center font-mono text-2xl font-bold'
                  initial={{ scale: 0 }}
                  animate={{ scale: [0, 1.2, 1] }}
                  exit={{ scale: [1, 1.2, 0] }}
                >
                  x
                </motion.div>
              ) : (
                <motion.div
                  key='close'
                  className='relative h-8 w-8'
                  initial={{ scale: 0 }}
                  animate={{ scale: [0, 1.2, 1] }}
                  exit={{ scale: [1, 1.2, 0] }}
                >
                  <Image
                    src='/icons/common/burger.svg'
                    fill
                    sizes='28px'
                    alt={t('common:seo.title')}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </button>
        </li>

        <li className='col-span-2 flex justify-center'>
          <Link
            href='/'
            className='logo-image relative block h-8 w-36 shrink-0 hover:opacity-80 xl:h-9 xl:w-40 rtl:h-9 rtl:w-32'
            data-location='header'
          >
            <Image
              className='rtl:hidden'
              src='/icons/logo-en.svg'
              fill
              sizes='163px'
              alt={t('common:seo.title')}
            />
            <Image
              className='ltr:hidden'
              src='/icons/logo-ar.svg'
              fill
              sizes='163px'
              alt={t('common:seo.title')}
            />
          </Link>
        </li>

        <li className='flex justify-end [&>a]:p-1'>
          <LanguageSwitch />
        </li>
      </ul>
    </>
  );
};

export default MobileHeader;
