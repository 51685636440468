import { useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import HideOnWebview from '@/components/webview/HideOnWebview';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import { HEADER_NAV } from './nav';
import UserProfileButton from './UserProfileButton';

type Props = {
  settings: LayoutProps['header'];
};

const DesktopHeader = ({ settings }: Props) => {
  const { t } = useTranslation();
  const { locale, pathname } = useRouter();

  const menu = useMemo(() => HEADER_NAV.filter((n) => n.title !== 'support_privacy'), []);

  if (settings?.type === 'mini') {
    return (
      <nav className='container hidden items-center justify-between py-3 lg:flex'>
        <div className='flex grow items-center gap-1 md:gap-4'>
          <Link
            href='/'
            className='logo-image relative block shrink-0 hover:opacity-90'
            data-location='header'
            role='menuitem'
          >
            <Image
              className='rtl:hidden'
              src='/icons/logo-en.svg'
              width={150}
              height={150}
              sizes='163px'
              alt='logo'
            />
            <Image
              className='ltr:hidden'
              src='/icons/logo-ar.svg'
              width={100}
              height={70}
              sizes='163px'
              alt='logo'
            />
          </Link>
        </div>

        <div className='flex items-center justify-end gap-2 text-sm xl:gap-2 xl:text-base'>
          <LanguageSwitch />
        </div>
      </nav>
    );
  }

  if (settings?.type === 'booking') {
    return (
      <HideOnWebview>
        <nav className='container flex items-center justify-between py-3'>
          <div className='flex grow items-center gap-1 md:gap-4'>
            <Link
              href='/'
              className='logo-image relative block shrink-0 hover:opacity-90'
              data-location='header'
              role='menuitem'
            >
              <Image
                className='rtl:hidden'
                src='/icons/logo-en.svg'
                width={120}
                height={150}
                sizes='163px'
                alt='logo'
              />
              <Image
                className='ltr:hidden'
                src='/icons/logo-ar.svg'
                width={100}
                height={70}
                sizes='163px'
                alt='logo'
              />
            </Link>
          </div>

          <div className='flex items-center justify-end gap-2 text-sm xl:gap-2 xl:text-base'>
            <LanguageSwitch />
          </div>
        </nav>
      </HideOnWebview>
    );
  }

  return (
    <nav className='container hidden items-center justify-between py-3 lg:flex'>
      <ul
        className='flex items-center gap-4 text-sm uppercase xl:gap-6 xl:text-base rtl:text-xs xl:rtl:text-base'
        role='menu'
      >
        <li role='presentation'>
          <Link
            href='/'
            className='logo-image relative block shrink-0 hover:opacity-90'
            data-location='header'
            role='menuitem'
          >
            <Image
              className='rtl:hidden'
              src='/icons/logo-en.svg'
              width={150}
              height={150}
              sizes='163px'
              alt='logo'
            />
            <Image
              className='ltr:hidden'
              src='/icons/logo-ar.svg'
              width={100}
              height={70}
              sizes='163px'
              alt='logo'
            />
          </Link>
        </li>
        {settings?.type === 'main' &&
          menu.map((link) => {
            const path = link[`href_${locale}` as 'href_en'] || link.href_en;
            return (
              <li key={path} role='presentation'>
                <Link
                  href={link.external ? path : link.href_en}
                  className={`flex items-center gap-2 whitespace-nowrap border-b-2 border-primary p-1 transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary focus:outline-none ${pathname === path ? 'text-primary' : 'border-transparent'}`}
                  data-title={link.title}
                  data-location='header'
                  role='menuitem'
                >
                  {t(`common:nav.${link.title}`)}
                </Link>
              </li>
            );
          })}
      </ul>

      <ul className='flex gap-1 text-sm xl:gap-4' role='menu'>
        <li className='[&>a]:text-sm [&>a]:xl:text-sm' role='presentation'>
          <LanguageSwitch />
        </li>

        {['main'].includes(String(settings?.type)) && (
          <li className='relative shrink-0 text-sm' role='presentation'>
            <UserProfileButton />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default DesktopHeader;
