import { Fragment } from 'react';
import HideOnTawakkalna from '@/components/twk/HideOnTawakkalna';
import HideOnWebview from '../webview/HideOnWebview';
import Footer from './footer';
import Header from './header';

const Layout = ({ children, header, footer, hideLayout }: LayoutProps) => {
  if (hideLayout) {
    return <main id='main'>{children}</main>;
  }

  return (
    <Fragment>
      <HideOnTawakkalna>
        <HideOnWebview>
          <Header settings={header} />
        </HideOnWebview>
      </HideOnTawakkalna>
      <main id='main'>{children}</main>
      <HideOnTawakkalna>
        <HideOnWebview>
          <Footer settings={footer} />
        </HideOnWebview>
      </HideOnTawakkalna>
    </Fragment>
  );
};

export default Layout;
